var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-3 blue darken-4"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"text-sm-h5 text-xs-h6 font-weight-bold white--text"},[_vm._v(" Email Templates ")]),_c('v-spacer')],1)])],1),_c('v-col',{staticClass:"my-2",attrs:{"md":"12 ","cols":"12"}},[(_vm.emailTemplateUpdateSuccess)?_c('v-alert',{attrs:{"dense":"","text":"","type":"success"}},[_vm._v(" Email template updated successfully. ")]):_vm._e(),_c('v-card',{staticClass:"pa-2",staticStyle:{"height":"100%"},attrs:{"min-height":"300px"}},[_c('v-card-title',[_vm._v(" Email Template "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"pa-2"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers1,"items":_vm.emailTemplates,"items-per-page":5,"search":_vm.search,"custom-filter":_vm.filterOnlyCapsText},scopedSlots:_vm._u([{key:"item.ename",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ename)+" ")]}},{key:"item.esubject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.esubject)+" ")]}},{key:"item.ebody",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatHtmlText(item.ebody))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"warning"},on:{"click":function($event){return _vm.editEmailTemplate(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil-outline ")])],1)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"1200px","height":"1000px"},model:{value:(_vm.editTemplate),callback:function ($$v) {_vm.editTemplate=$$v},expression:"editTemplate"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline blue darken-4 white--text"},[_vm._v(" Edit Email Template ")]),_c('v-form',{ref:"form1",staticClass:"mx-2",attrs:{"lazy-validation":""}},[_c('v-card-text',{staticClass:"pa-5"},[_c('div',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"filled":"","clearable":"","label":"Name","type":"text","rules":_vm.nameRules},model:{value:(_vm.ename),callback:function ($$v) {_vm.ename=$$v},expression:"ename"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"filled":"","clearable":"","label":"Subject","type":"text","rules":_vm.nameRules},model:{value:(_vm.esubject),callback:function ($$v) {_vm.esubject=$$v},expression:"esubject"}})],1)],1),_c('v-row',[_c('v-col',[_c('p',[_vm._v("Email Body")]),_c('vue-ckeditor',{attrs:{"filled":"","clearable":"","label":"Email Body","type":"text","rules":_vm.nameRules,"config":_vm.config},model:{value:(_vm.ebody),callback:function ($$v) {_vm.ebody=$$v},expression:"ebody"}})],1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","dark":"","color":"red"},on:{"click":function($event){return _vm.closingRequest()}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"text":"","dark":"","color":"success"},on:{"click":function($event){return _vm.saveEmailTemplate(_vm.templateID)}}},[_vm._v(" Save ")])],1)],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }