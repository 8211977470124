<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Users
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            User Managment
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <div class="pa-2">
            <v-data-table
              :headers="headers"
              :items="requestLog"
              item-key="id"
              class="elevation-1"
              :search="search"
              
            >
              <template v-slot:item.append> </template>
              <template v-slot:item.detail="{ item }">
                <v-btn color="success" @click="openUser(item.ID)">Show</v-btn>
              </template>
                <template v-slot:item.action="{ item }">
                <v-btn
                  fab
                  dark
                  small
                  color="red accent-4"
                  @click="deleteUser(item.firebaseID)"
                  ><v-icon dark> mdi-delete </v-icon>
                </v-btn>
                <!--<v-btn fab dark small color="green" @click="exportdata(item)"
                  ><v-icon dark> mdi-microsoft-excel </v-icon></v-btn
                >-->
              </template>
            </v-data-table>
          </div>
        </v-card>

        <v-snackbar v-model="snackbar" :timeout="timeout">
          User deleted successfully.
        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import {API_BASE_URL, API_HELIUM_URL } from '../constants';

export default {
  setup() {},
  data() {
    return {
      items: ["ETH", "BTC", "HNT", "Zelle", "PayPal", "Venmo"],
      message: "",
      amount: 0,
      dialog: false,
      hotspotDetails: [],
      hotspotTotal: [],
      url: "",
      mapurl: "",
      token: JSON.parse(localStorage.getItem("user")),
      start: "",
      today: "",
      search: "",
      uid: "",
      oracleprice: null,
      installation: "",
      requestLog: [],
      maxearn: 0,
      snackbar: false,
      timeout: 4000,
      attrs: ''
    };
  },
  computed: {
    headers() {
      return [
        { text: "Username", value: "name" },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
        },
        { text: "Commission %", value: "commission" },
        {
          text: "Created Date",
          value: "createDate",
          sortable: true,
          align: "center",
        },
        { text: "Contract Signed?", value: "signed", align: "center" },
        {
          text: "Contract Sign Date",
          value: "createdsign",
          align: "center",
        },
        { text: "Owned Hotspot", value: "assignHotspot", align: "center" },
        { text: "Details", value: "detail", sortable: false, align: "center" },
        { text: "Actions", value: "action", sortable: false, align: "center" }
      ];
    },
  },
  beforeMount() {
    //this.getuid();
    var start = new Date();
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var m2 = today.getMonth() - 2;
    var yyyy = today.getFullYear();
    var yyyy2 = today.getFullYear() - 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (m2 < 10) {
      m2 = "0" + m2;
    }
    this.today = yyyy + "-" + mm + "-" + dd;
    this.start = yyyy2 + "-" + m2 + "-" + dd;
    this.getRequest();
    this.getOracleValue();
  },

  methods: {
    getOracleValue() {
      axios
        .get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.oracleprice = resp.data;
        });
    },
    sendRequest(amount, message) {
      console.log(this.message);
      axios.post(`${API_BASE_URL}/user/request/${this.uid}`, {
        payment: this.message,
        amount: this.amount,
      });
      this.dialog = false;
      this.getRequest();
    },
    getuid() {
      //this.$cookies.set('uid', this.user.data.uid)
      //this.uid = this.user.data.uid
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.hotspotDet(resp.data[0].Haddress, this.start, this.today);
        });
    },
    hotspotDet(address, start, today) {
      (this.dialog = false),
        axios
          .get(`${API_HELIUM_URL}/v1/hotspots/${address}`, {
            headers: { accept: "application/json" },
          })
          .then((resp) => {
            this.hotspotDetails = resp.data;
            var [year, month, day] = this.hotspotDetails.data.timestamp_added
              .substring(0, 10)
              .split("-");
            this.installation = new Date(year, month, day);
            axios
              .get(
                `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${this.installation
                  .toISOString()
                  .slice(0, 10)}`,
                {
                  headers: { accept: "application/json" },
                }
              )
              .then((resp) => {
                this.hotspotTotal = resp.data;
                this.mapurl = [
                  "https://www.openstreetmap.org/export/embed.html?bbox=" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "&layer=mapnik&marker=" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng,
                ].join("");
                this.maxearn = parseInt(
                  ((this.hotspotTotal.data.total / 100) * 20).toFixed(2)
                );
              });
          });
    },
    openUser(userid) {
      this.$router.push({ name: "user", params: { id: userid } });
    },
    getRequest() {
      axios
        .get(`${API_BASE_URL}/users`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          var requestLog = resp.data;
          requestLog.forEach(
            (obj) => (obj["createDate"] = obj["createDate"].substring(0, 10))
          );
          axios
            .get(`${API_BASE_URL}/usersHotspot`, {
              headers: {
                accept: "application/json",
                "x-access-token": this.token.accessToken,
              },
            })
            .then((resp) => {
              var hotspotOwn = resp.data;
              var i;

              for (i = 0; i < requestLog.length; i++) {
                if (
                  requestLog[i]["createdsign"] == "2021-07-06T18:47:22.000Z"
                ) {
                  requestLog[i]["createdsign"] = "";
                }
                if (requestLog[i]["signed"] == null) {
                  requestLog[i]["signed"] = "Temp Whitelist";
                }
                if (requestLog[i]["signed"] == "") {
                  requestLog[i]["signed"] = "No";
                }
                if (requestLog[i]["signed"] == 0) {
                  requestLog[i]["signed"] = "No";
                  requestLog[i]["createdsign"] = "";
                }
                if (requestLog[i]["signed"] == 1) {
                  requestLog[i]["signed"] = "Yes";
                }
                
                if (
                  hotspotOwn.find(
                    ({ firebaseID }) => firebaseID === requestLog[i].firebaseID
                  )
                ) {
                  const result = hotspotOwn.find(
                    ({ firebaseID }) => firebaseID === requestLog[i].firebaseID
                  );
                  requestLog[i].assignHotspot = result.assignHotspot;
                } else {
                  requestLog[i].assignHotspot = 0;
                  requestLog[i]["createdsign"] = "";
                   requestLog[i]["signed"] = "No";
                }

              }
              this.requestLog = requestLog;
            });
        });
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },



    saveHotspot(address) {
      let payload = { haddress: address };
      let res = axios.put(`${API_BASE_URL}/hotspot/${this.uid}`, payload);
      let data = res.data;
    },
    deleteUser(ID) {
      if(confirm("Do you really want to delete this user?")){
         axios
            .delete(
              `${API_BASE_URL}/user/${ID}`,
              {
                headers: {
                  accept: "application/json",
                  "x-access-token": this.token.accessToken,
                },
              }
            )
            .then((resp) => {
              this.snackbar = true;
              this.getRequest();
            });
        
      }
     
    },
  },
};
</script>