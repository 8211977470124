<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Pending requests
            </div>
            <v-dialog
              v-model="editReq"
              persistent
              max-width="1200px"
              height="1000px"
            >
              <v-card>
                <v-card-title class="headline blue darken-4 white--text">
                  Edit request
                </v-card-title>
                <v-card-text class="pa-5">
                  <div>
                    <v-row
                      ><v-autocomplete
                        :items="useremail"
                        label="User email"
                        v-model="requser"
                        :readonly=true
                      ></v-autocomplete>
                    </v-row>
                    <v-row>
                      amount
                      <input
                        v-model="amount"
                        color="#474DFF"
                        filled
                        clearable
                        type="number"
                      />
                      <v-select
                        :items="items"
                        label="Payment method"
                        v-model="message"
                      ></v-select>

                      <p v-if="message != 'HNT'">Amount in HNT</p>
                      <input
                        v-if="message != 'HNT'"
                        filled
                        clearable
                        type="number"
                        label="Amount in HNT"
                        v-model="HNTchanges"
                      />
                    </v-row>
                    <v-row> </v-row
                    ><v-select
                      :items="status"
                      label="Payment status"
                      v-model="paymentStatus"
                    ></v-select>
                    <v-card-actions class="pt-0">
                      <v-btn
                        text
                        dark
                        color="red"
                        @click="deleteRequest(reqid)"
                      >
                        delete permanently </v-btn
                      ><v-spacer></v-spacer>

                      <v-btn text dark color="red" @click="closingRequest()">
                        Close
                      </v-btn>
                      <v-btn
                        text
                        dark
                        color="success"
                        @click="editRequest(reqid, paymentStatus)"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog" max-width="1200px" height="1000px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined dark small v-bind="attrs" v-on="on">
                  Manual request
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="headline blue darken-4 white--text">
                  Manual request
                </v-card-title>
                <v-card-text class="pa-5">
                  <div>
                    <v-row
                      ><v-autocomplete
                        :items="useremail"
                        label="User email"
                        v-model="requser"
                      ></v-autocomplete>
                    </v-row>
                    <v-row>
                      amount
                      <input
                        v-model="amount"
                        color="#474DFF"
                        filled
                        clearable
                        type="number"
                      />
                      <v-select
                        :items="items"
                        label="Payment method"
                        v-model="message"
                      ></v-select>

                      <p v-if="message != 'HNT'">Amount in HNT</p>
                      <input
                        v-if="message != 'HNT'"
                        filled
                        clearable
                        type="number"
                        label="Amount in HNT"
                        v-model="HNTchanges"
                      />
                    </v-row>
                    <v-row> </v-row>

                    <v-select
                      :items="status"
                      label="Payment status"
                      v-model="paymentStatus"
                    ></v-select>
                    <v-card-actions class="pt-0">
                      <v-spacer></v-spacer>
                      <v-btn text dark color="red" @click="dialog = false">
                        Close
                      </v-btn>
                      <v-btn
                        text
                        dark
                        color="success"
                        @click="
                          sendRequest(requser, amount, paymentStatus, message)
                        "
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </v-card>
      </v-col>
      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Transfer activity
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <div class="pa-2">
            <!--    <v-data-table
                                :headers="headers"
                                :items="requestLog"
                                item-key="ID"
                                class="elevation-1"
                                :search="search"
                                :custom-filter="filterOnlyCapsText"
                        >
                            <template v-slot:body.append>
                            </template>
                                            <template v-slot:item.action="{ item }">
                                                <v-dialog
      v-model="approve"
        :retain-focus="false"
      max-width="350"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="success"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Approve
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          Are you sure to Approve?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="approve = false"
          >
            NO
          </v-btn>
          <v-btn
            color="success"
            text
            @click=approved(item.ID)
          >
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="reject"
      :retain-focus="false"
      max-width="350"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="error"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Reject
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          Are you sure to reject?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="reject = false"
          >
            NO
          </v-btn>
          <v-btn
            color="error"
            text
            
            @click=rejecttransaction(item.ID)
          >
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
                    <v-btn color="warning" @click="viewRequest(item.ID)" ><v-icon
          dark
        >
          mdi-pencil-outline
        </v-icon></v-btn>
                </template>
                        </v-data-table> -->
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="filteredDesserts"
              show-select
              :search="search"
              :custom-filter="filterOnlyCapsText"
              item-key="ID"
            >
              <template v-slot:header="{  }">
                <tr class="grey lighten-3">
                  <th>
                    <v-icon>mdi-menu</v-icon>
                  </th>
                  <th v-for="header in headers" :key="header.text">
                    <div v-if="filters.hasOwnProperty(header.value)">
                      <v-autocomplete
                        flat
                        hide-details
                        multiple
                        attach
                        chips
                        dense
                        clearable
                        :items="columnValueList(header.value)"
                        v-model="filters[header.value]"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 5"
                            ><span>
                              {{ item }}
                            </span>
                          </v-chip>
                          <span v-if="index === 5" class="grey--text caption">
                            (+{{ filters[header.value].length - 5 }} others)
                          </span>
                        </template>
                      </v-autocomplete>
                    </div>
                  </th>
                </tr>
              </template>
              <template v-slot:item.entered="{ item }">{{format_date(item.entered)}}</template>
              <template v-slot:item.action="{ item }">
                <v-dialog
                  v-model="approve"
                  :retain-focus="false"
                  max-width="350"
                >
                  <template v-slot:activator="{ on, attrs }"  >
                    <v-btn color="success" @click="setSelectedID(item.ID)" dark v-bind="attrs" v-on="on" v-bind:style= "[item.flag=='Deleted' ? {'background': 'gray !important'} : '']"  :disabled="item.flag=='Deleted'" >
                      Approve 
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">
                      Are you sure to Approve?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="success" text @click="approve = false">
                        NO
                      </v-btn>
                      <v-btn color="success" text @click="approved()">
                        YES
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="reject"
                  :retain-focus="false"
                  max-width="350"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="error" @click="setSelectedID(item.ID)" dark v-bind="attrs" v-on="on" v-bind:style= "[item.flag=='Deleted' ? {'background': 'gray !important'} : '']" :disabled="item.flag=='Deleted'" >
                      Reject
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">
                      Are you sure to reject?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="error" text @click="reject = false">
                        NO
                      </v-btn>
                      <v-btn
                        color="error"
                        text
                        @click="rejecttransaction()"
                      >
                        YES
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-btn v-bind:style= "[item.flag=='Deleted' ? {'background': 'gray !important'} : '']" :disabled="item.flag=='Deleted'"     fab
      dark
      small color="warning" @click="viewRequest(item.ID)"
                  ><v-icon dark> mdi-pencil-outline </v-icon></v-btn
                >
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
            <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import {API_BASE_URL, API_HELIUM_URL} from '../constants';
import moment from 'moment';
export default {
  header: "",
  data() {
    return {
      //items: ["ETH", "BTC", "HNT", "Zelle", "PayPal", "Venmo"],
      items: ["HNT"],
      message: "",
      header: "",
      amount: 0,
      reject: false,
      approve: false,
      editReq: false,
                  snackbar: false,
      text: 'Save Successful',
      timeout: 4000,
      hotspotDetails: [],
      hotspotTotal: [],
      url: "",
      dialog: false,
      users: {},
      HNTchanges: 0,
      reqid: 0,
      useremail: {},
      requser: "",
      mapurl: "",
      paymentStatus: "",
      token: JSON.parse(localStorage.getItem("user")),
      start: "",
      today: "",
      status: ["Pending", "Approved", "Completed"],
      uid: "",
      search: "",
      oracleprice: null,
      installation: "",
      requestLog: [],
      maxearn: 0,
      selected: [],
      filters: {
        email: [],
        payment: [],
        flag: [],
      },
      selectedID: ''
    };
  },
  computed: {
    headers() {
      return [
        { text: "name", value: "name" },
        { text: "email", value: "email" },
        {
          text: "Request Date",
          align: "start",
          sortable: false,
          value: "entered",
           width: "150px"
        },
        {
          text: "status",
          value: "flag",
        },
        { text: "HNT payed", value: "HNTchange" },
        { text: "payment method", value: "payment" },
        { text: "amount", value: "amount" },
        { text: "Actions", value: "action", sortable: false, align: "center" },
      ];
    },
    filteredDesserts() {
      return this.requestLog.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
  },
  beforeMount() {
    //this.getuid();
    var start = new Date();
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var m2 = today.getMonth() - 2;
    var yyyy = today.getFullYear();
    var yyyy2 = today.getFullYear() - 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (m2 < 10) {
      m2 = "0" + m2;
    }
    this.today = yyyy + "-" + mm + "-" + dd;
    this.start = yyyy2 + "-" + m2 + "-" + dd;
    this.getRequest();
    //this.hotspotDet("112Z69CrW1sTv6BDTv23Hx8th6vUiuAK6zyEviJdpXrd7Dmb4Awz", this.start, this.today);

    this.getOracleValue();
  },

  methods: {
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.requestLog.slice();
    },
    columnValueList(val) {
      return this.requestLog.map((d) => d[val]);
    },
    getOracleValue() {
      axios
        .get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.oracleprice = resp.data;
        });
    },
    setSelectedID(ID){
      this.selectedID = ID;
    },
    sendRequest(user, amount, status, message) {
      let details = this.users.find((o) => o.email === user);
      if (status == "Pending") {
        status = 0;
      } else if (status == "Completed") {
        status = 1;
      } else if (status == "Deleted") {
        status = 2;
      } else if (status == "Approved") {
        status = 3;
      }
      axios
        .post(`${API_BASE_URL}/user/request/${details.firebaseID}`, {
          payment: this.message,
          amount: this.amount,
          flag: status,
          HNTchanges: this.HNTchanges,
        })
        .then((resp) => {
          this.snackbar=true
          this.dialog = false;
          console.log(resp);
          this.getRequest();
          this.message = "";
          this.amount = 0;
          this.HNTchanges = 0;
          this.requser = "";
        });
    },
    viewRequest(ID) {
      this.reqid = ID;
      console.log(ID);
      this.editReq = true;
      axios
        .get(`${API_BASE_URL}/request/${ID}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          let details = this.users.find(
            (o) => o.firebaseID === resp.data[0].firebaseID
          );
          this.requser = details.email;
          this.amount = resp.data[0].amount;
          this.HNTchanges = resp.data[0].HNTchange;
          this.message = resp.data[0].payment;
          if (resp.data[0].flag == 0) {
            this.paymentStatus = "Pending";
          } else if (resp.data[0].flag == 1) {
            this.paymentStatus = "Completed";
          } else if (resp.data[0].flag == 2) {
            this.paymentStatus = "Deleted";
          } else if (resp.data[0].flag == 3) {
            this.paymentStatus = "Approved";
          }
        });
    },

    editRequest(ID, status) {
      console.log(ID);
      this.editReq = true;
      if (status == "Pending") {
        status = 0;
      } else if (status == "Completed") {
        status = 1;
      } else if (status == "Deleted") {
        status = 2;
      } else if (status == "Approved") {
        status = 3;
      }
      let details = this.users.find((o) => o.email === this.requser);
      let payload = {
        payment: this.message,
        requser: details.firebaseID,
        amount: this.amount,
        flag: status,
        HNTchanges: this.HNTchanges,
      };

      axios
        .put(`${API_BASE_URL}/request/${ID}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.getRequest();
          this.message = "";
          this.amount = 0;
          this.HNTchanges = 0;
          this.requser = "";
          this.editReq = false;
        });
    },

    closingRequest() {
      this.message = "";
      this.amount = 0;
      this.HNTchanges = 0;
      this.requser = "";
      this.editReq = false;
    },
    deleteRequest(ID) {
      axios
        .delete(`${API_BASE_URL}/request/${ID}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          console.log("permanent deleted");
          this.editReq = false;
          this.getRequest();
        });
    },
    getuid() {
      //this.$cookies.set('uid', this.user.data.uid)
      //this.uid = this.user.data.uid
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.hotspotDet(resp.data[0].Haddress, this.start, this.today);
        });
    },
    hotspotDet(address, start, today) {
      (this.dialog = false),
        axios
          .get(`${API_HELIUM_URL}/v1/hotspots/${address}`, {
            headers: { accept: "application/json" },
          })
          .then((resp) => {
            this.hotspotDetails = resp.data;
            var [year, month, day] = this.hotspotDetails.data.timestamp_added
              .substring(0, 10)
              .split("-");
            this.installation = new Date(year, month, day);
            axios
              .get(
                `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${this.installation
                  .toISOString()
                  .slice(0, 10)}`,
                {
                  headers: { accept: "application/json" },
                }
              )
              .then((resp) => {
                this.hotspotTotal = resp.data;
                this.mapurl = [
                  "https://www.openstreetmap.org/export/embed.html?bbox=" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "&layer=mapnik&marker=" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng,
                ].join("");
                this.maxearn = parseInt(
                  ((this.hotspotTotal.data.total / 100) * 20).toFixed(2)
                );
              });
          });
    },
    getRequest() {
      axios
        .get(`${API_BASE_URL}/users`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.users = resp.data;
          this.useremail = this.users.map(function (v) {
            return v["email"];
          });
        });
      axios
        .get(`${API_BASE_URL}/requests`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.requestLog = resp.data;
          console.log('this.requestLog', this.requestLog);
          for (var i in this.requestLog) {
            if (this.requestLog[i].payment == "HNT") {
              this.requestLog[i].HNTchange = this.requestLog[i].amount;
            }
            if (this.requestLog[i].flag == 0) {
              this.requestLog[i].flag = "Pending";
            } else if (this.requestLog[i].flag == 1) {
              this.requestLog[i].flag = "Completed";
            } else if (this.requestLog[i].flag == 2) {
              this.requestLog[i].flag = "Deleted";
            } else if (this.requestLog[i].flag == 3) {
              this.requestLog[i].flag = "Approved";
            }
          }
        });
    },
    rejecttransaction() {
      let itemID  =this.selectedID;
      let payload = { flag: 2 };
      axios
        .post(`${API_BASE_URL}/requeststatus/${itemID}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.reject = false;
          this.snackbar=true
          this.text = "Rejected Successfully";
          this.selectedID = '';
          this.getRequest();
        });
    },

    approved() {
      let itemID = this.selectedID;
      let payload = { flag: 3 };
      axios
        .post(`${API_BASE_URL}/requeststatus/${itemID}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.approve = false;
          this.snackbar=true
           this.text = "Approved Successfully";
          this.getRequest();
        }).catch(error => {
          let message = typeof error.response !== "undefined" ? error.response.data.message : error.message;
          alert(message);
          this.approve = false;
          this.snackbar=true
          this.selectedID = '';
          this.getRequest();
        });
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },

    saveHotspot(address) {
      let payload = { haddress: address };
      let res = axios.put(
        `${API_BASE_URL}/hotspot/${this.uid}`,
        payload
      );
      let data = res.data;
    },
    format_date(value){
         if (value) {
           return moment(String(value)).format('MM-DD-YYYY')
          }
      },
  },
};
</script>