import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import firebase from 'firebase/app'
import store from "./store";
import './assets/app.scss'
import VueApexCharts from 'vue-apexcharts'
import VueCookies from 'vue-cookies';
import VueJsonToCsv from 'vue-json-to-csv'
import { FIRE_BASE } from './constants';

Vue.use(VueCookies);
Vue.use(VueApexCharts);
Vue.use(VueJsonToCsv);
Vue.component('vue-json-to-csv', VueJsonToCsv);
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false

const configOptions = {
  apiKey: FIRE_BASE.apiKey,
  authDomain: FIRE_BASE.authDomain,
  databaseURL: FIRE_BASE.databaseURL,
  projectId: FIRE_BASE.projectId,
  storageBucket: FIRE_BASE.storageBucket,
  messagingSenderId: FIRE_BASE.messagingSenderId,
  appId: FIRE_BASE.appId
};

firebase.initializeApp(configOptions);

firebase.auth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
  VueCookies.set("uid", user.uid, "7d");
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
