<template>
  <v-container>
    <v-row>
      <v-col v-if="error" cols="12">
        <v-alert type="error">
          No hotspot assined to your accout
        </v-alert></v-col
      >

      <v-col v-else cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Hotspots
            </div>
            <!--                        <v-dialog
                                v-model="dialog"
                                width="500"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        outlined
                                        dark
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    Track Hotspot
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="headline blue darken-4 white--text">
                                    Track Hotspot
                                </v-card-title>
                                <v-card-text class="pa-3">
                                    <div>
                                        <v-text-field
                                                v-model="message"
                                                :append-outer-icon="message ? 'mdi-send' : 'mdi-send'"
                                                filled
                                                clearable
                                                label="Enter Hotspot Address"
                                                type="text"
                                                @click:append-outer=hotspotDet(message,start,today)
                                        ></v-text-field>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
        <v-select
        dark
          :items="arr"
          label="Leased Hotspot"
          dense
          v-model="hname"
          @input=getHotspot(hname)
        ></v-select>
        </div>-->
          </div>
        </v-card>
      </v-col>

      <v-col md="4" cols="12">
        <v-card class="my-2 border-left-1">
          <div>
            <div>
              <v-card-title class="text-h6"> Hotspot Name </v-card-title>
              <v-card-subtitle>
                {{ hotspotname }}
                <span class="mx-3 gold_4--text">({{ hotspotstatus }})</span>
              </v-card-subtitle>
            </div>
          </div>
        </v-card>
        <v-card class="my-2 border-left-2">
          <div>
            <div>
              <v-card-title class="text-h6"> Address </v-card-title>

              <v-card-subtitle>
                {{ hotspotadd }}
              </v-card-subtitle>
            </div>
          </div>
        </v-card>
        <v-card class="my-2 border-left-3">
          <div>
            <div>
              <v-card-title class="text-h6"> Blockchain Date </v-card-title>
            <!--   <v-menu
                ref="menu"
                v-model="installdate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
               <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="installdate"
                    label="Installation Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="installdate"
                  :active-picker.sync="activePicker"
                  :max="
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="2010-01-01"
                ></v-date-picker>
              </v-menu> <v-btn dark color="warning">
                  <v-icon dark> mdi-pencil </v-icon>
                </v-btn>-->

              <v-card-subtitle>
                {{ installation.toISOString().slice(0, 10) }}
               
              </v-card-subtitle>
            </div>
          </div>
        </v-card>
        <v-card class="my-2 border-left-3">
          <div>
            <div>
              <v-card-title class="text-h6"> Install Date </v-card-title>
              <v-card-subtitle v-if="installDate==null">
                {{ installation.toISOString().slice(0, 10) }}
               
              </v-card-subtitle>
                            <v-card-subtitle v-else>
                {{ installDate.slice(0, 10) }}
               
              </v-card-subtitle>
            </div>
          </div>
        </v-card>
        <v-card class="my-2 border-left-4">
          <div>
            <div>
              <v-card-subtitle class="pb-0">
                Total Reward for the hotspot in the last 3 Months
              </v-card-subtitle>
              <v-card-subtitle class="pb-0">
                Total earning {{ commission }} %
              </v-card-subtitle>
              <v-card-title class="text-h6 gold_4--text">
                {{ ((totalearn / 100) * commission).toFixed(2) }} HNT
                <v-switch
                  v-model="swcommission"
                  inset
                  :label="`Show user reward`"
                  ><v-icon>mdi-eye</v-icon></v-switch
                >
              </v-card-title>
            </div>
          </div>
        </v-card>
        <v-card class="my-2 border-left-4">
          <div>
            <div>
              <v-card-subtitle class="pb-0">
                Account assigned to this hotspot:
              </v-card-subtitle>
              <v-card-title class="text-h6 gold_4--text">
                <div v-for="(item, i) in ownerinfo" :key="i">
        
                  <v-btn
                    v-if="ownerinfo[i].role == 0 && ownerinfo[i].removeDate == null"
                    depressed
                    color="error"
                    @click="openUser(ownerinfo[i].ID)"
                  >
                    {{ ownerinfo[i].email }}
                  </v-btn>
                </div>
              </v-card-title>
            </div>
            <div>
              <v-card-subtitle class="pb-0">
                Account Referral to this hotspot:
              </v-card-subtitle>
              <v-card-title class="text-h6 gold_4--text">
                <div v-for="(item, i) in ownerinfo" :key="i">
                  <v-btn
                    v-if="ownerinfo[i].role == 1"
                    depressed
                    color="purple darken-1"
                    dark
                    @click="openUser(ownerinfo[i].ID)"
                  >
                    {{ ownerinfo[i].email }}
                  </v-btn>
                </div>
              </v-card-title>
            </div>
                        <div>
              <v-card-subtitle class="pb-0">
                Previously assigned to:
              </v-card-subtitle>
              <v-card-title class="text-h6 gold_4--text">
                <div v-for="(item, i) in ownerinfo" :key="i">
                  <v-btn
                    v-if="ownerinfo[i].role == 0 && ownerinfo[i].removeDate != null"
                    depressed
                    color="error"
                    dark
                    @click="openUser(ownerinfo[i].ID)"
                  >
                    {{ ownerinfo[i].email }}
                  </v-btn>
                </div>
              </v-card-title>
            </div>
          </div>
        </v-card>
        <v-card class="my-2 border-left-4">
          <!--<v-row>
                            <v-col lg="3" md="6" cols="12" class="pa-1">
                                <v-card color="blue darken-4" dark class="text-center">
                                    <v-card-text>
                                        <div class="subtitle-2">Today</div>
                                        <div class="subtitle-1"><b class="white--text">{{today.toFixed(2)}}</b> <span
                                                class="subtitle-2">HNT</span></div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col lg="3" md="6" cols="12" class="pa-1">
                                <v-card color="blue darken-4" dark class="text-center">
                                    <v-card-text>
                                        <div class="subtitle-2">Yesterday</div>
                                        <div class="subtitle-1"><b class="white--text">{{yesterday.toFixed(2)}}</b> <span
                                                class="subtitle-2">HNT</span></div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col lg="3" md="6" cols="12" class="pa-1">
                                <v-card color="blue darken-4" dark class="text-center">
                                    <v-card-text>
                                        <div class="subtitle-2">7 days earn</div>
                                        <div class="subtitle-1"><b class="white--text">{{week.toFixed(2)}}</b> <span
                                                class="subtitle-2">HNT</span></div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col lg="3" md="6" cols="12" class="pa-1">
                                <v-card color="blue darken-4" dark class="text-center">
                                    <v-card-text>
                                        <div class="subtitle-2">30 day earn</div>
                                        <div class="subtitle-1"><b class="white--text">{{sums.toFixed(2)}}</b> <span
                                                class="subtitle-2">HNT</span></div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                                                    </v-row>-->
        </v-card>
      </v-col>
      <v-col md="8" cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <iframe
            :src="mapurl"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            style="border: 1px solid black"
          ></iframe>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="my-2">
      <v-card-text>
        <v-row class="align-center">
          <v-col md="4" cols="12">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Start Date"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="4" cols="12">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date2"
                  label="End Date"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date2"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="4" cols="12" class="text-center">
            <v-btn
              tile
              dark
              color="blue darken-4"
              @click="filtereward(date, date2)"
            >
              <v-icon left> mdi-content-save </v-icon>
              Show rewards
            </v-btn>
          </v-col>
          <v-col md="6" cols="12">
            <v-card-subtitle class="pb-0">
              Total Reward for the selected periods
            </v-card-subtitle>
            <v-card-title class="text-h6 gold_4--text">
              {{ hotspotTotal.data.total.toFixed(2) }} HNT
            </v-card-title>
          </v-col>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <div class="pa-2">
        <v-data-table
          :headers="headers"
          :items="activity.data"
          item-key="hash"
          class="elevation-1"
          :search="search"
          :custom-filter="filterOnlyCapsText"
        >
          <template v-slot:body.append>
            <tr>
              <td></td>
              <td></td>
              <td colspan="1"></td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import {API_BASE_URL, API_HELIUM_URL} from '../constants';

export default {
  setup() {},
  data() {
    return {
      dialog: false,
      hotspotDetails: [],
      hotspotTotal: [],
      url: "",
      mapurl: "",
      error: false,
      start: "",
      ownerinfo: {},
      today1: "",
      totalearn: 0,
      hotspotstatus: "",
      hotspotname: "",
      hotspotadd: "",
      message: "",
      uid: this.$cookies.get("uid"),
      hname: "",
      commission: 20,
      haddress: "",
      installation: "",
      today: 0,
      sums: 0,
      installDate:"",
      yesterday: 0,
      hotspotAll: "",
      week: 0,
      token: JSON.parse(localStorage.getItem("user")),
      arr: [],
      accountTotal: [],
      rewards: [],
      search: "",
      activity: [],
      menu1: false,
      menu2: false,
      message2: "",
      date: "",
      usercommission: 0,
      date2: "",
      swcommission: false,
      dateFormatted: "",
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Hash",
          align: "start",
          sortable: false,
          value: "hash",
        },
        { text: "Time", value: "timestamp", dataType: "Date" },
        { text: "Gateway", value: "gateway" },
        { text: "Account", value: "account" },
        { text: "Amout in HNT", value: "amount" },
      ];
    },
    computedDateFormatted() {
      return this.formatDate(this.date2);
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    swcommission(newValue) {
      if (newValue == true) {
        this.commission = 100;
      }
      if (newValue == false) {
        this.commission = this.usercommission;
      }
    },
  },
  beforeMount() {
    this.getAddress()
    var start = new Date();
    var today1 = new Date();
    var dd = today1.getDate();
    var mm = today1.getMonth() + 1;
    var m2 = today1.getMonth() - 2;
    var yyyy = today1.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (m2 < 10) {
      m2 = "0" + m2;
    }
    this.today1 = yyyy + "-" + mm + "-" + dd;
    this.start = yyyy + "-" + m2 + "-" + dd;
    //this.getuid();
    //this.getHotspotName();
    var today = new Date();
    var yesterday = new Date();
    var week = new Date();
    var month = new Date();
    yesterday.setDate(today.getDate() - 1);
    week.setDate(today.getDate() - 7);
    month.setDate(today.getDate() - 30);
    
    this.filtereward(start, today1);
    //this.dataCard(today.toISOString().slice(0,10),yesterday.toISOString().slice(0,10),week.toISOString().slice(0,10),month.toISOString().slice(0,10));
  },
  methods: {
    openUser(name) {
      this.$router.push({ name: "user", params: { id: name } });
      //console.log(name);
    },
    getAddress() {
      axios
        .get(`${API_BASE_URL}/account`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.accountAdded = resp.data;
          this.getHotspot(this.$route.params.id);
        });
    },
    hotspotassign(address) {
      axios
        .get(`${API_BASE_URL}/hotspotassign/${address}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          //console.log(resp.data)
          this.ownerinfo = resp.data;
          this.commission = resp.data[0].commission;
          this.usercommission = resp.data[0].commission;
        });
    },
    changecommission() {
      this.commission = 0;
    },
    dataCard(haddress, today, yesterday, week, month) {
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${yesterday}&max_time=${today}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.yesterday =
            ((resp.data.data.sum / 1000000000000).toFixed(2) / 100) *
            this.commission;
        });
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${week}&max_time=${today}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.week =
            ((resp.data.data.sum / 1000000000000).toFixed(2) / 100) *
            this.commission;
        });
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${today}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.today =
            ((resp.data.data.sum / 1000000000000).toFixed(2) / 100) *
            this.commission;
        });
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${month}&max_time=${today}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.sums =
            ((resp.data.data.sum / 1000000000000).toFixed(2) / 100) *
            this.commission;
        });
    },
    getHotspot(hname) {
      //Updating Hname from address
      axios
        .get(`${API_HELIUM_URL}/v1/hotspots/${hname}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          const availacc = this.accountAdded.some(user => user.address == resp.data.data.owner)
          if(availacc) {
            axios.get(`${API_BASE_URL}/hotspot/${resp.data.data.address}`, {
              headers: { accept: "application/json" ,
              "x-access-token": this.token.accessToken}
            })
            .then((resp) => {
                this.installDate= resp.data[0].installDate
            })
            this.haddress = resp.data.data.address;
            //this.dataCard();
            this.hotspotassign(this.haddress);
            this.hotspotDet(this.haddress, this.start, this.today1);
          }
        })      
          //console.log(resp.data.data[0].address);
                
    },
    getHotspotName() {
      var i;
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.hotspotAll = resp.data;
          
          for (i = 0; i < this.hotspotAll.length; i++) {
            axios
              .get(
                `${API_HELIUM_URL}/v1/hotspots/${this.hotspotAll[i].Haddress}`,
                {
                  headers: { accept: "application/json" },
                }
              )
              .then((resp) => {
                this.arr.push(resp.data.data.name);
              });
          }
        });
    },
    getuid() {
      //this.$cookies.set('uid', this.user.data.uid)
      //this.uid = this.user.data.uid
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            this.haddress = resp.data[0].Haddress;
            this.hotspotDet(resp.data[0].Haddress, this.start, this.today1);
          } catch (err) {
            this.error = true;
            console.log("empty profile");
          }
        });
    },
    hotspotDet(address, start, today) {
      (this.dialog = false),
        axios
          .get(`${API_HELIUM_URL}/v1/hotspots/${address}`, {
            headers: { accept: "application/json" },
          })
          .then((resp) => {
            this.hotspotDetails = resp.data;
            var [year, month, day] = this.hotspotDetails.data.timestamp_added
              .substring(0, 10)
              .split("-");
            this.installation = new Date(year, month - 1, day);
            this.hname = resp.data.data.name;
            axios
              .get(
                `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${this.installation
                  .toISOString()
                  .slice(0, 10)}`,
                {
                  headers: { accept: "application/json" },
                }
              )
              .then((resp) => {
                this.hotspotTotal = resp.data;
                this.totalearn = this.hotspotTotal.data.total;
                this.hotspotadd = this.hotspotDetails.data.address;
                this.hotspotstatus = this.hotspotDetails.data.status.online;
                this.hotspotname = this.hotspotDetails.data.name;
                var today = new Date();
                var yesterday = new Date();
                var week = new Date();
                var month = new Date();
                yesterday.setDate(today.getDate() - 1);
                week.setDate(today.getDate() - 7);
                month.setDate(today.getDate() - 30);
                //this.dataCard(address,today.toISOString().slice(0,10),yesterday.toISOString().slice(0,10),week.toISOString().slice(0,10),month.toISOString().slice(0,10));
                this.mapurl = [
                  "https://www.openstreetmap.org/export/embed.html?bbox=" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "&layer=mapnik&marker=" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng,
                ].join("");
              });
          });
    },
    filtereward(start, today) {
      console.log(start),
        console.log(today),
        this.accountActivity(this.haddress, start, today);
    },
    rewardaccount(address, start, today) {
      axios
        .get(
          `${API_HELIUM_URL}/v1/accounts/${address}/rewards/sum?max_time=${today}&min_time=${start}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.accountTotal = resp.data;
        });
      axios
        .get(
          `${API_HELIUM_URL}/v1/accounts/${address}/rewards?max_time=${today}&min_time=${start}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.rewards = resp.data;
        });
    },
    accountActivity(address, start, today) {
      var cursor = [];
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards?max_time=${today}&min_time=${start}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          axios
            .get(
              `${API_HELIUM_URL}/v1/hotspots/${address}/rewards?max_time=${today}&min_time=${start}&cursor=${resp.data.cursor}`,
              {
                headers: { accept: "application/json" },
              }
            )
            .then((resp) => {
              resp.data.data.forEach(
                (item) => (item.amount = parseInt(item.amount) / 100000000)
              );
              this.activity = resp.data;
            });
        });
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?max_time=${today}&min_time=${start}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.hotspotTotal = resp.data;
        });
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().replace(' ',' ').indexOf(search) !== -1
      );
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>