<template>
  <v-container>
    <v-row>

      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Email
            </div>
            <v-spacer></v-spacer>
            
          </div>
        </v-card>
      </v-col>

      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Historical Records
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-row class="select-wrapper">
              <v-col
                class="d-flex"
                cols="12"
                sm="4"
              >
                <v-select
                  :items="items"
                  label="Request Type"
                  v-model="selectedType"
                ></v-select>
              </v-col>
            
          </v-row>

          <div class="pa-2">
            <v-data-table
              :headers="headers1"
              :items="contactEmails"
              :items-per-page="5"
              class="elevation-1"
              :search="search"
            >
              <template v-slot:item.name="{ item }">
                {{item.name}}
              </template>
              <template v-slot:item.email="{ item }">
                {{item.email}}
              </template>

            </v-data-table>
          </div>

        </v-card>
      </v-col>

    
    </v-row>
  </v-container>
</template>





<script>

import axios from "axios";
import { API_BASE_URL, API_HELIUM_URL } from "../constants";
import { mapGetters } from "vuex";

export default {
  setup() {},
  data() {
    return {
      nameRules: [(v) => !!v || "Required"],
      items:[
        {text: 'Host Support Request', value:'Host Support Request'},
        {text: 'Update Hotspot Address Request', value:'Update Hotspot Address Request'},
        {text: 'Antenna Change Request', value:'Antenna Change Request'},
        {text: 'No Longer Want Hotspot', value:'No Longer Want Hotspot'}
      ],
      token: JSON.parse(localStorage.getItem("user")),
      search: "",
      uid: "",
      attrs: '',
      on: '',
      selectedType:'Host Support Request',
      contactEmails:[]
    };
  },
  computed: {
    headers1() {
      return[
          { text: 'Name',value:'name'},
          { text: 'Email', value: 'email' },
          { text: 'Type of Email', value: 'type', filter: this.nameFilter, },
        ]
    }
  },
  beforeMount() {
    //this.getuid();

    this.getContactUsEmails();
  },

  methods: {
      nameFilter(value) {
        if (!this.selectedType) {
          return true;
        }

        return value === this.selectedType
      },
    getuid() {
      //this.$cookies.set('uid', this.user.data.uid)
      //this.uid = this.user.data.uid
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.hotspotDet(resp.data[0].Haddress, this.start, this.today);
        });
    },
    openUser(userid) {
      this.$router.push({ name: "user", params: { id: userid } });
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    getContactUsEmails(){
      axios
      .get(`${API_BASE_URL}/contactemails`, {
        headers: {  accept: "application/json",
          "x-access-token": this.token.accessToken, },
      })
      .then((resp) => {
        console.log(resp.data)
        //remove duplicates email
        //const keys = ['email'];
        this.contactEmails = resp.data
      });
    }
  },
};
</script>

<style scoped>
  .select-wrapper {
    padding-left: 15px;
  }
</style>