<template>
  <v-app-bar
    app
    class="white px-md-5"
    elevation="0"
    :height="$vuetify.breakpoint.mdAndUp ? '100px' : '70px'"
  >
    <v-container>
      <div class="d-none d-md-block">
        <v-row align="center">
          <div class="d-flex align-center">
            <v-img
              alt="Logo"
              class="shrink mr-2"
              contain
              src="@/assets/logo.png"
              transition="scale-transition"
              width="95"
            />
          </div>
          <div class="mr-2 subtitle-2">
            HNT Conversion Price:
            <span class="gold_4--text darken-4 ml-2" @click="HNTopen()">
              $
              {{
                oracleprice.data != null
                  ? (oracleprice.data.price / 100000000).toFixed(2)
                  : ""
              }}
            </span>
          </div>
          <v-spacer></v-spacer>
          <div>
            <router-link :to="{ name: 'account' }" class="menu-item"
              >Account / Hotspot</router-link
            >
            <router-link :to="'/users'" class="menu-item"
              >User managment</router-link
            >
            <router-link :to="'/reports'" class="menu-item"
              >Reports</router-link
            >
            <router-link :to="'/commissions'" class="menu-item"
              >Request</router-link
            >
            <router-link :to="'/emailtemplate'" class="menu-item"
              >Email Template</router-link
            >
            <router-link :to="'/email'" class="menu-item"
              >Email</router-link
            >
            <router-link :to="'/emailsettings'" class="menu-item"
              >Email Settings</router-link
            >
            <router-link :to="'/adminusers'" class="menu-item"
              >Admin User</router-link
            >
            
          </div><v-spacer ></v-spacer>
          <v-btn
            color="blue darken-4"
            dark
            outlined
            rounded
            :to="{ name: 'login' }"
          >
            Admin
          </v-btn>
          
          <!--<div class="text-right" v-if="user.loggedIn">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="blue darken-4"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  rounded
                >
                  {{ user.data != null ? user.data.displayName : "" }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item dense @click.prevent="signOut">
                  <v-list-item-title class="subtitle-2"
                    >Sign Out</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>-->
        </v-row>
      </div>

      <v-row align="center" class="d-md-none my-0 justify-space-between">
        <v-menu v-if="user.loggedIn" left bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <template>
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
          </template>

          <v-list>
            <v-list-item :to="{ name: 'account' }" dense>
              <v-list-item-title class="subtitle-2">Account / Hotspot</v-list-item-title>
            </v-list-item>
            <v-list-item :to="'/users'" dense>
              <v-list-item-title class="subtitle-2">User managment</v-list-item-title>
            </v-list-item>
            <v-list-item :to="'/reports'" dense>
              <v-list-item-title class="subtitle-2">Reports</v-list-item-title>
            </v-list-item>
            <v-list-item :to="'/commissions'" dense>
              <v-list-item-title class="subtitle-2">Requests</v-list-item-title>
            </v-list-item>
                        <v-list-item :to="'/adminusers'" dense>
              <v-list-item-title class="subtitle-2">Admin User</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="d-flex align-center">
          <router-link to="/">
            <v-img
              alt="Logo"
              class="shrink mr-2"
              contain
              src="@/assets/logo.png"
              transition="scale-transition"
              width="65"
            />
          </router-link>
        </div>
        <div v-if="user.loggedIn" class="text-right">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue darken-4"
                dark
                v-bind="attrs"
                v-on="on"
                outlined
                rounded
                small
                max-width="50px"
              >
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title
                  class="subtitle-2 gold_4--text darken-4 text-center"
                >
                  Hi, Admin
                  <div class="mr-2 subtitle-2 black--text">
                    HNT Conversion Price<span
                      class="purple--text darken-4 ml-2"
                      href="https://coinmarketcap.com/currencies/helium/"
                      >{{
                        oracleprice.data != null
                          ? (oracleprice.data.price / 100000000).toFixed(2)
                          : ""
                      }}
                      $
                    </span>
                  </div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item dense @click.prevent="signOut">
                <v-list-item-title class="subtitle-2"
                  >Sign Out</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-btn
          href="/login"
          color="blue darken-4"
          dark
          outlined
          rounded
          small
          v-else
        >
          <span class="mr-2">Login</span>
        </v-btn>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import firebase from "firebase/app";

export default {
  name: "app-header",

  data: () => {
    return {
      oracleprice: {
        data: "",
      },
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  mounted() {
    this.getOracleValue();
  },
  methods: {
    getOracleValue() {
      axios
        .get(`https://api.helium.io/v1/oracle/prices/current`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.oracleprice = resp.data;
        });
    },
    HNTopen() {
      window.open("https://coinmarketcap.com/currencies/helium/", "_blank");
    },
    signOut() {
      localStorage.removeItem("user");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.menu-item {
  text-decoration: none !important;
  padding: 5px 10px;
  color: var(--v-secondary-base);
  font-size: 14px;

  &:hover {
    color: var(--v-primary-base);
  }

  &.router-link-exact-active {
    color: var(--v-gold_4-base);
  }

  &:not(:last-child) {
    border-right: 1px solid var(--v-primary-base);
  }
}
</style>